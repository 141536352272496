import React from 'react';
import './step-instruction.component.scss';

export default function StepInstruction({ step, stepImg }) {
    return (
        <div className="step-instruction">
            <p className="t-sans t-p1">{step}</p>
            <div className="step-instruction__image">
                <img src={stepImg} alt="step instruction picture" />
            </div>
        </div>
    )
}
